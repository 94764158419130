@import '../../../../scss/_theme-bootstrap.scss';

.cr21-refresh .product-size-select-v1 {
  div.size-select--dropdown.select2-container {
    @include body-text--bold;
    a.select2-choice {
      height: 32px;
      .select2-chosen {
        font-size: 16px;
        line-height: get-line-height(16px, 32px);
      }
    }
  }
}

@if $cr21_mpp == true {
  div.mpp-sort-menu--v3.select2-drop.select2-drop-active,
  div.size-picker-menu--v1.select2-drop.select2-drop-active {
    color: $color--black;
    border: 1px solid $color--black;
    border-radius: 20px;
    margin-top: 5px;
    .select2-highlighted {
      background: transparent;
      color: $color--black;
    }
    .select2-result-active {
      .select2-result-label {
        &::after {
          content: ' ';
          width: 5px;
          height: 5px;
          background: $color--black;
          border-radius: 3px;
          display: flex;
          position: absolute;
          top: 17px;
          #{$ldirection}: 10px;
        }
      }
    }
    &.select2-drop-above {
      margin-top: -5px;
    }
    .select2-results {
      padding: 5px;
    }
    .select2-result {
      position: relative;
      height: auto;
      padding: 0;
    }
    .select2-result-label {
      @include body-text;
      @include swap_direction(padding, 10px 7px 10px 25px);
      font-size: 14px;
      white-space: nowrap;
      &::before {
        content: ' ';
        width: 9px;
        height: 9px;
        border: 1px solid $color--black;
        border-radius: 5px;
        display: flex;
        position: absolute;
        top: 14px;
        #{$ldirection}: 7px;
      }
    }
  }
  .cr21-refresh {
    .mpp-sort__container.mpp-sort__container--v3 div.mpp-sort-menu--v3,
    .product-size-select-v1 div.size-select--dropdown {
      &.select2-container {
        width: auto;
        border-radius: 30px;
        min-width: unset;
        .select2-choice {
          padding-#{$ldirection}: 25px;
          background-color: $color--white;
          color: $color--black;
          border-radius: 30px;
          border-color: $color-text-grey;
          border: unset;
        }
        .select2-chosen {
          @include body-text--small-bold;
          margin: 0;
          line-height: 45px;
        }
        .select2-arrow {
          background: $color--white;
          b {
            &:before {
              color: $color--white;
              border-color: $color--black;
            }
          }
        }
        &.select2-dropdown-open {
          border: 1px solid $color--black;
        }
      }
      select.mpp-sort-menu,
      select.size-select--dropdown {
        @include body-text--small-bold;
        color: $color--black;
        background: $color--white;
        border: 1px solid $color--gray--lighter;
        border-radius: 30px;
      }
      &.select--plain:before {
        border-color: $color--black;
      }
    }
  }
}
select.size-select--dropdown {
  @include primary-select;
  width: auto;
  height: 32px;
  font-size: 16px;
  line-height: get-line-height(12px, 12px);
}
